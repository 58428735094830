import { RouteLocationNormalizedLoaded } from 'vue-router';
import { FirebaseApp } from './FirebaseApp';
import { FirebaseWeb } from './FirebaseWeb';
import VueGtag from "vue-gtag";

//Per il debug di analytics
    //DOCUMENTAZIONE
    //https://firebase.google.com/docs/analytics/debugview
    //PAGINA DI DEBUG SU FIREBASE
    //https://console.firebase.google.com/project/_/analytics/debugview
    //ANDROID
    //ABILITARE IL PROPRIO DISPOSITIVO IN MODALITA' DEBUG (SOSTITUIRE PACKAGE_NAME)
    //adb shell setprop debug.firebase.analytics.app package_name     
    //DISABILITARE LA MODALITA' DEBUG SUL PROPRIO DISPOSITIVO
    //adb shell setprop debug.firebase.analytics.app .none.
    //IOS
    //ABILITARE IL PROPRIO DISPOSITIVO IN MODALITA' DEBUG DA INSERIRE NEGLI ARGOMENTI DELLA RIGA DI COMANDO DI XCODE
    //-FIRDebugEnabled 
    //DISABILITARE LA MODALITA' DEBUG SUL PROPRIO DISPOSITIVO DA INSERIRE NEGLI ARGOMENTI DELLA RIGA DI COMANDO DI XCODE
    // -FIRDebugDisabled
//-------------------------------------------------------

//Firebase configuration object
//https://firebase.google.com/docs/analytics/get-started?platform=web
var platform = "unset";
var app_name =  "eu.uose.app";
var analytics_id_UA = ""; //Per analytics web
var initialized = false;

var configWeb = {
    apiKey: "AIzaSyC5K3VGlMns1b4Nxxu-B_d818UzfeheN6Q",
    authDomain: "uose-dc9f4.firebaseapp.com",
    projectId: "uose-dc9f4",
    appId: "1:605595752092:web:a121b123bfe3ea9754bf59",
}

var configAndroid = {
    apiKey: "AIzaSyC6870vG07eKewR5yx6g8GLHeC8ikFfPUI",
    authDomain: "uose-dc9f4.firebaseapp.com",
    projectId: "uose-dc9f4",
    storageBucket: "uose-dc9f4.appspot.com",
    messagingSenderId: "605595752092",
    appId: "1:605595752092:android:8f24a9d299558be354bf59",
    measurementId: undefined
};

var configIos = {
    apiKey: "AIzaSyB5bGlW99bzfv-3bEcluqiPk2LEGICJFQM",
    authDomain: "uose-dc9f4.firebaseapp.com",
    projectId: "uose-dc9f4",
    appId: "1:605595752092:ios:ee4b0f30777e106154bf59",
}

//Init firebase
//Chiamare al deviceready
//L'inizializzazione serve per l'analytics WEB e APP
export function initFirebase(){
    if(initialized)
        return;

    if(window.cordova){
        platform = FirebaseApp.getPlatform();
        FirebaseApp.initFirebase(configIos, configAndroid, platform);
    }
    else {
        platform = FirebaseWeb.getPlatform();
        FirebaseWeb.sendAnalytics(analytics_id_UA, app_name, platform);
        FirebaseWeb.initFirebase(configWeb);
    }

    initialized = true;
}

//Send analytics
export function sendAnalytics(route: RouteLocationNormalizedLoaded){
    if(window.cordova && initialized)
        FirebaseApp.sendAnalytics(route, app_name, platform);
}