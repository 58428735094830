import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/fiera/:slug',
            name: 'fiera_detail',
            redirect: { name: 'fiera_communities' },
            component: () => import('../community/views/community.vue'),
            children: [
                {
                    path: '/fiera/info/:slug',
                    name: 'fiera_info',
                    component: () => import('../community/views/info/info.vue'),
                },
                {
                    path: '/fiera/communities/:slug',
                    name: 'fiera_communities',
                    component: () => import('./views/communities.vue'),
                },
                {
                    path: '/fiera/attivita/:slug',
                    name: 'fiera_attivita',
                    component: () => import('../community/views/bacheca/bacheca.vue'),
                },
                {
                    path: '/fiera/forum/:slug',
                    name: 'fiera_forum',
                    component: () => import('../community/views/forum/forum.vue'),
                },
                {
                    path: '/fiera/shop/:slug',
                    name: 'fiera_shop',
                    component: () => import('../community/views/shop/shop.vue'),
                }
            ]
        },
    ];
};
