import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/globalSearch',
            name: 'global_search',
            meta: {
                hideFooter: true
            },
            component: () => import('./views/globalSearch.vue'),
        },
        {
            path: '/home',
            name: 'discover',
            component: () => import('./views/home.vue'),
        }
    ];
};