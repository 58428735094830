
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import SocialLogin from '../views/login/components/socialLogin.vue';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
        SocialLogin
    }
})
export default class Login extends Vue {

    @Prop() documents: OM.MandatoryDocumentAppVM[];

}
