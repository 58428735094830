import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/communities/:slug',
            name: 'community',
            redirect: { name: 'community_bacheca' },
            meta: {},
            component: () => import('./views/community.vue'),
            children: [
                {
                    path: '/communities/:slug/Bacheca',
                    name: 'community_bacheca',
                    meta: { hideFooter: true },
                    component: () => import('./views/bacheca/bacheca.vue'),
                },
                {
                    path: '/communities/:slug/Info',
                    name: 'community_info',
                    meta: { hideFooter: true },
                    component: () => import('./views/info/info.vue'),
                },
                {
                    path: '/communities/:slug/forum',
                    name: 'community_forum',
                    meta: { hideFooter: true },
                    component: () => import('./views/forum/forum.vue'),
                },
                {
                    path: '/communities/:slug/calendar-list',
                    name: 'community_events',
                    meta: { hideFooter: true },
                    component: () => import('./views/calendar/events.vue'),
                },
                {
                    path: '/communities/:slug/calendar',
                    name: 'community_calendar',
                    meta: { hideFooter: true },
                    component: () => import('./views/calendar/calendar.vue'),
                },
                // {
                //     path: '/communities/:slug/calendar/:datetime',
                //     name: 'community_calendar_date',
                //     meta: { hideFooter: true },
                //     component: () => import('./views/calendar/modals/dayEvents.vue'),
                // },
                {
                    path: '/communities/:slug/Shop',
                    name: 'community_shop',
                    meta: { hideFooter: true },
                    component: () => import('./views/shop/shop.vue'),
                },
                {
                    path: '/communities/:slug/Shop/:shopItemSlug',
                    name: 'community_shop_item',
                    meta: { hideFooter: true },
                    component: () => import('./views/shop/shopItemDetail.vue'),
                },
                {
                    path: '/communities/:slug/Cart',
                    name: 'community_cart',
                    meta: { hideFooter: true },
                    component: () => import('./views/shop/cartResume.vue'),
                },
                {
                    path: '/communities/:slug/Cart/settings',
                    name: 'community_cart_settings',
                    meta: { hideFooter: true },
                    component: () => import('./views/shop/cartSettings.vue'),
                },
            ]
        },
        {
            path: '/events/:slug',
            name: 'events',
            redirect: { name: 'events_bacheca' },
            meta: {},
            component: () => import('./views/community.vue'),
            children: [
                {
                    path: '/events/:slug/Bacheca',
                    name: 'events_bacheca',
                    meta: {hideFooter: true },
                    component: () => import('./views/bacheca/bacheca.vue'),
                },
                {
                    path: '/events/:slug/Info',
                    name: 'events_info',
                    meta: { hideFooter: true },
                    component: () => import('./views/info/info.vue'),
                },
                {
                    path: '/events/:slug/forum',
                    name: 'events_forum',
                    meta: { hideFooter: true },
                    component: () => import('./views/forum/forum.vue'),
                },
                {
                    path: '/events/:slug/Shop',
                    name: 'events_shop',
                    meta: { hideFooter: true },
                    component: () => import('./views/shop/shop.vue'),
                },
                {
                    path: '/events/:slug/Shop/:shopItemSlug',
                    name: 'events_shop_item',
                    meta: { hideFooter: true },
                    component: () => import('./views/shop/shopItemDetail.vue'),
                },
                {
                    path: '/events/:slug/Cart',
                    name: 'events_cart',
                    meta: { hideFooter: true },
                    component: () => import('./views/shop/cartResume.vue'),
                },
                {
                    path: '/events/:slug/Cart/settings',
                    name: 'events_cart_settings',
                    meta: { hideFooter: true },
                    component: () => import('./views/shop/cartSettings.vue'),
                },
            ]
        },
    ];
};