
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import MandatoryDocuments from '@/components/mandatoryDocuments.vue'

@Options({
    components: {
        MandatoryDocuments
    }
})
export default class MandatoryDocumentsAfterLogin extends Vue {

    @Prop() documents: OM.MandatoryDocumentAppVM[];
    @Prop() refuseCallback: () => void;
    @Prop() acceptCallback: () => void;

}
