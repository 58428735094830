import { RouteLocationNormalizedLoaded } from "vue-router";
import * as firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth/cordova";

export class FirebaseApp {
    
    static getPlatform(): string {
        if(device.platform != "iOS")
            return "android";
        else 
            return "iOS";
    }
    
    static sendAnalytics(route: RouteLocationNormalizedLoaded, app_name: string, platform: string) {
        if(!window.cordova){
            console.log("ERROR: CORDOVA NOT DEFINED!")
            return;
        }

        if(!FirebasePlugin){
            console.log("ERROR: FIREBASE PLUGIN UNDEFINED!")
            return;
        }
    
        FirebasePlugin.logEvent("page_view", {
            page_location : route.path,
            page_title : route.name,
            page_path: route.fullPath,
            app_name: app_name,
            platform: platform
        });
    }

    static initFirebase(configIos: any, configAndroid: any, platform: string){
        var app = null;

        window.firebase = firebase;

        // Initialize Firebase
        if(platform != "iOS")
            app = initializeApp(configAndroid);
        else
            app = initializeApp(configIos);

        const auth = getAuth(app);
    }

}