
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class TextareaAutosize extends Vue {

    @Prop({
        default: ''
    }) modelValue: [String, Number];

    @Prop({
        default: true
    }) autosize: boolean;

    @Prop({
        default: null
    }) minHeight: Number;

    @Prop({
        default: null
    }) maxHeight: Number;

    @Prop({
        default: false
    }) important: boolean;

    val: [String, Number] = null;
    maxHeightScroll: boolean = false;
    height: string = "auto";

    created() {
        this.val = this.modelValue;
    }

    mounted () {
        this.resize()
    }

    resize () {
        const important = this.isHeightImportant ? 'important' : '';
        this.height = `auto${important ? ' !important' : ''}`;
        this.$nextTick(() => {
            let contentHeight = this.$el.scrollHeight + 1;

            if (this.minHeight) {
                contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight;
            }

            if (this.maxHeight) {
                if (contentHeight > this.maxHeight) {
                    contentHeight = this.maxHeight;
                    this.maxHeightScroll = true;
                } else {
                    this.maxHeightScroll = false;
                }
            }

            const heightVal = contentHeight + 'px';
            this.height = `${heightVal}${important ? ' !important' : ''}`;
        })

        return this;
    }

    get computedStyles() {
        if (!this.autosize) 
            return {};
        
        return {
            resize: !this.isResizeImportant ? 'none' : 'none !important',
            height: this.height,
            overflow: this.maxHeightScroll ? 'auto' : (!this.isOverflowImportant ? 'hidden' : 'hidden !important')
        }
    }
    get isResizeImportant() {
        const imp = this.important;
        return imp === true || (Array.isArray(imp) && imp.includes('resize'));
    }
    get isOverflowImportant() {
        const imp = this.important;
        return imp === true || (Array.isArray(imp) && imp.includes('overflow'));
    }
    get isHeightImportant() {
        const imp = this.important;
        return imp === true || (Array.isArray(imp) && imp.includes('height'));
    }

    @Watch('modelValue')
    valueChanged() {
        this.val = this.modelValue;
    }

    @Watch('val')
    valChanged() {
        this.$nextTick(this.resize);
        this.$emit('update:modelValue', this.val);
    }

    @Watch('minHeight')
    minHeightChanged() {
        this.$nextTick(this.resize);
    }

    @Watch('maxHeight')
    maxHeightChanged() {
        this.$nextTick(this.resize);
    }

    @Watch('autosize')
    autosizeChanged() {
        if (this.val) 
            this.resize();
    }

}
