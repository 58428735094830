import router from '@/router';
import Axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import * as VM from '@/viewModel';
import { appendExtensionIfMissing } from '@/utils';

class _CommonServices {
    
    showSpinnerCb = () => {};
    hideSpinnerCb = () => {};
    unauthorizedCb = () => {};
    
    defaultGet<T = any>(url: string, params?: any, _showSpinner = true): Promise<T> {
        url = replaceUrl(url, params);
        
        let req: AxiosRequestConfig = {
            params
        };
        if(_showSpinner)
        this.showSpinnerCb();
        let prom = new Promise<T>((resolve, reject) => {
            Axios.get<T>(url, req)
            .then(x => {
                if(x && x.hasOwnProperty("data"))
                    resolve(x.data);
                else
                    resolve(<any>x);
            }).catch( err => {
                if(checkIfIsNotConfirmedError(err)){
                    router.push("/confirmedCode")
                    reject();
                }
                else if (err.response && err.response.status == 401) {
                    this.unauthorizedCb();
                }
                else
                    reject(err);
            })
            .finally(() => {
                if(_showSpinner)
                this.hideSpinnerCb();
            });
        });
        return prom;
    }
    defaultPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig, _showSpinner = true): Promise<T> {
        if(_showSpinner)
        this.showSpinnerCb()
        let prom = new Promise<T>((resolve, reject) => {
            Axios.post(url, data, config).then(x => {
                if(x && x.hasOwnProperty("data"))
                    resolve(x.data);
                else
                    resolve(<any>x);
            }).catch( err => {
                if(checkIfIsNotConfirmedError(err)){
                    router.push("/confirmedCode")
                    reject();
                }
                else if (err.response && err.response.status == 401) {
                    this.unauthorizedCb();
                }
                else
                    reject(err);
            })
            .finally(() => {
                if(_showSpinner)
                this.hideSpinnerCb();
            });
        });
        return prom;
    }
    private addFile(form, fileUri, key) {

        if(fileUri.startsWith("cdvfile://"))
            (<any>window).resolveLocalFileSystemURL(fileUri, (entry) => {
                fileUri = entry.toURL();
            })

        // if(fileUri.indexOf("msf") > -1){
        //     (<string>fileUri).replaceAll("%3A", ":");
        // } else {
        //     fileUri = decodeURI(fileUri);
        //     fileUri = encodeURI(fileUri);
        // }
        
        console.log("adding file " + fileUri);
        console.log("with key " + key);
        let self = this;
        let promise = new Promise((resolve, reject) => {
            
            (<any>window).resolveLocalFileSystemURL(fileUri, function (fileEntry) {
                fileEntry.file(function (file) {
                    
                    (file as any).name = appendExtensionIfMissing(file.name, file.type);

                    var reader = new FileReader();
                    reader.onloadend = function (fileReadResult: any) {
                        var data = new Uint8Array(fileReadResult.target.result);


                        var blob = self.createBlob(data, file.type || self.getMimeType(file.name));
                        form.append(key, blob, file.name);
                        resolve(null);
                    };
                    
                    reader.onerror = function (fileReadResult) {
                        reject(fileReadResult);
                    };
                    reader.readAsArrayBuffer(file);
                });
            }, (err) => {
                console.log(err)
            });
            
        })
        return promise;
    }
    private createBlob(data, type) {
        var r;
        r = new window.Blob([data], {type: type});
        return r;
    }
    private mimetypes = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png'
    };
    private getMimeType(fileName) {
        var extension = fileName.split('.').pop();
        return this.mimetypes.hasOwnProperty(extension) ? this.mimetypes[extension] : undefined;
    }
    private async convertModelToFormData(data = {}, form = null, namespace = '') {
        let files = {};
        let model = {};
        for (let propertyName in data) {
            if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File && window.cordova){
                model[propertyName] = data[propertyName].localURL;
            } 
            else if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File) 
            {
                files[propertyName] = data[propertyName];
                // files[propertyName] = await this.readFileAsync(data[propertyName]);
                // files[propertyName].name = data[propertyName].name;
                // files[propertyName].lastModified = data[propertyName].lastModified;
                // files[propertyName].lastModifiedDate = data[propertyName].lastModifiedDate;


                // this.readFileAsync(data[propertyName])
                // .then(x => {
                //     var file = new File([x], data[propertyName].name, { type: data[propertyName].type, lastModified: data[propertyName].lastModified })
                //     files[propertyName] = file;
                // });
                
                //files[propertyName] = data[propertyName];

                // var fileObj: any = {};
                // fileObj.lastModified = data[propertyName].lastModified;
                // fileObj.lastModifiedDate = data[propertyName].lastModifiedDate;
                // fileObj.name = data[propertyName].name;
                // fileObj.size = data[propertyName].size;
                // fileObj.type = data[propertyName].type;
                // fileObj.webkitRelativePath = "";
                // console.log("FileObj");
                // console.log(fileObj);
                // var file = new File([fileObj], fileObj.name, { type: fileObj.type })
                // files[propertyName] = file;

                // // var blob = new Blob([JSON.stringify(fileObj)]);
                // var file = new File([pippo], fileObj.name, { type: "text/plain", lastModified: fileObj.lastModified })
                // files[propertyName] = file;
            } 
            else
                model[propertyName] = data[propertyName]
        }
        console.log("Files Property End");
        console.log(files);
        console.log("cracker");
        let formData = form || new FormData();
        for (let propertyName in model) {
            if (!model.hasOwnProperty(propertyName) || !model[propertyName]) 
            continue;
            
            let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date){
                formData.append(formKey, model[propertyName].toISOString());
            } else if (model[propertyName] instanceof File) {
                formData.append(formKey, model[propertyName]);
            } else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach(async (element, index) => {
                    let tempFormKey = `${formKey}[${index}]`;
                    if(element instanceof File){
                        tempFormKey = tempFormKey.substring(0, tempFormKey.length - 3);
                        formData.append(tempFormKey, element);
                    } else if(typeof element == "string" && window.cordova){
                        if(element.startsWith("https://localhost"))
                            element = element.replace("https://localhost", "file://");

                        if(element.startsWith("file:///") || element.startsWith("cdvfile://")){
                            tempFormKey = tempFormKey.substring(0, tempFormKey.length - 3);
                            await this.addFile(formData, element, tempFormKey);
                        }
                    } else if (typeof element === 'object') {
                        this.convertModelToFormData(element, formData, tempFormKey);
                    } else {
                        formData.append(tempFormKey, element.toString());
                    } 
                });
            } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)){
                this.convertModelToFormData(model[propertyName], formData, formKey);
            } else {
                if(typeof model[propertyName] == "string" && window.cordova){
                    if(model[propertyName].startsWith("https://localhost"))
                            model[propertyName] = model[propertyName].replace("https://localhost", "file://");

                    if(model[propertyName].startsWith("file:///")  || model[propertyName].startsWith("cdvfile://")){
                        await this.addFile(formData, model[propertyName], formKey);
                    } else {
                        formData.append(formKey, model[propertyName].toString());
                    }
                } else {
                    formData.append(formKey, model[propertyName].toString());
                }
            }
        }
        
    for (let propertyName in files) {
        if (files.hasOwnProperty(propertyName)) {
            formData.append(propertyName, files[propertyName]);
        }
    }
    return formData;
}

readFileAsync(file) {
    return new Promise<Blob>((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = function() {
            console.log("Successful file write: " + this.result);
            var blob = new Blob([new Uint8Array(<any>this.result)], { type: file.type });
            resolve(blob);
        };
    })
}   

async uploadFilesToUrl<T = any>(url: string, file: File[], params: { [key: string]: any }, 
    onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
    var data = new FormData();
    
    if(file)
        params["files"] = file;

    if (params) {
        data = await this.convertModelToFormData(params);
    }

    var config = {
        onUploadProgress: function (ev: any) {
            if(typeof onUploadProgress == 'function')
            onUploadProgress((100 * ev.loaded) / ev.total);
        }
    };
    
    if(_showSpinner)
        this.showSpinnerCb();

    let prom = new Promise<T>((resolve, reject) => {
        Axios.post<T>(url, data, config).then(x => {
            if(x && x.data)
                resolve(x.data);
            else
                resolve(<any>x);
        })
        .catch( err => {
            reject(err);
        })
        .finally(() => {
            if(_showSpinner)
                this.hideSpinnerCb();
        });
    });
    return prom;
}

async uploadFileToUrl<T = any>(url: string, file: File, params: { [key: string]: any }, 
    onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
    var data = new FormData();
    
    if(file)
        params["file"] = file;

    if (params) {
        data = await this.convertModelToFormData(params);
    }

    var config = {
        onUploadProgress: function (ev: any) {
            if(typeof onUploadProgress == 'function')
            onUploadProgress((100 * ev.loaded) / ev.total);
        }
    };
    
    if(_showSpinner)
        this.showSpinnerCb();

    let prom = new Promise<T>((resolve, reject) => {
        Axios.post<T>(url, data, config).then(x => {
            if(x && x.data)
                resolve(x.data);
            else
                resolve(<any>x);
        })
        .catch( err => {
            reject(err);
        })
        .finally(() => {
            if(_showSpinner)
                this.hideSpinnerCb();
        });
    });
    return prom;
}
    
    setAuthToken(token: string) {
        Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        window.localStorage.setItem('authtoken', token);
    }
    destroyToken() {
        Axios.defaults.headers.common['Authorization'] = "";
        window.localStorage.removeItem('authtoken');
    }
    constructor() {
        let token = window.localStorage.getItem('authtoken');
        if (token){
            this.setAuthToken(token);
        }

        Axios.interceptors.request.use((request: AxiosRequestConfig) => {
            if(process.env.NODE_ENV === 'development'){
                request.headers["ngrok-skip-browser-warning"] = 10; //questo header serve a skippare i warning di ngrok free 
            }

            return request;
        })

        Axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(!error)
            return Promise.reject();
            
            if(!error.response)
            return Promise.reject(error);
            
            if (error.response.status == 401) {
                this.unauthorizedCb();
            } else if (error.response.status[0] == 5) {
                console.log("500");
                console.log(error);
            } else {
                if(error.response.data)
                    return Promise.reject(error.response.data);
                else
                    return Promise.reject(error);
            }
        });
    }
}

function replaceUrl(url: string, params?: any): string {
    
    if(!params || url.indexOf("{") == -1)
    return url;
    
    var beginIndex = url.indexOf("{");
    var endIndex = url.indexOf("}");
    
    var property = url.substring(beginIndex + 1, endIndex);
    var value = params[property];
    
    console.log(value)
    
    url = url.substring(0, beginIndex) + value + url.substr(endIndex + 1);
    delete params[property];
    
    return replaceUrl(url, params);
}

function checkIfIsNotConfirmedError(err: any){
    if(err && 
        (err.Response && err.Response.Status == 403 && err.Message == VM.validationemailerror ||
        err.response && err.response.status == 403 && err.message == VM.validationemailerror))
        return true;
    else
        return false;
}
        
        function playAndroidVideo(file){
            let path:string = "www/assets/videos/";
            let targetPath:any = file.applicationDirectory + path;
            
            //first - resolve target path in bundled file structure:
            file.resolveLocalFilesystemUrl(targetPath)
            .then((entry: any)=>{
                
                let dirPath = entry.toNativeURL();
                //alert('target entry: '+entry + ", - dirPath: "+dirPath);
                
                //then - resolve save folder in dataDirectory:
                file.resolveLocalFilesystemUrl(file.dataDirectory)
                .then((entry: any)=>{        
                    let savePath = entry.toNativeURL();
                    //alert('save entry: '+entry + ", - savePath: "+savePath);
                    
                    //then - copy file to saveFolder
                    file.copyFile(dirPath, "video.mp4", savePath, "video.mp4")
                    .then((entry: any)=>{
                        
                        let newPath = entry.toNativeURL();
                        //alert("File copied, entry.toNativeURL(): "+newPath);
                        let options = { 
                            successCallback: function() {
                                //alert("Video playback OK.");              
                            },
                            errorCallback: function(errMsg) {
                                alert("Error playing video file! " + errMsg);
                            },           
                            shouldAutoClose: true
                        }
                        window["plugins"].streamingMedia.playVideo(newPath, options);
                        
                        
                    }).catch((error)=>{
                        alert("error copyFile: "+error);
                    }); 
                    
                }).catch((error)=>{
                    alert("error resolveLocalFilesystemUrl (save folder): "+error);
                }); 
                
            }).catch((error)=>{
                alert("error resolveLocalFilesystemUrl (target): "+error);
            });     
            
        }
        
        export let CommonServices = new _CommonServices();