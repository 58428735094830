import moment from 'moment';
import { getUniversalDate } from './utils';
import store from './store';
import { LocalizationServices } from './services/LocalizationServices';

export default {

    date(value, _format = "", _showTime = false) {
        var tempValue = JSON.parse(JSON.stringify(value));

        if (tempValue) {
            tempValue = getUniversalDate(tempValue);
            let format;
            if(!_format){
                format = 'YYYY MMMM DD';
                if(_showTime)
                    format += 'HH:mm:ss';
            } else {
                format = _format;
            }

            return moment(tempValue).locale(store.state.preferredCulture).format(format);
        }
    },

    chatDate(value: any) {
        if(!value)
            return;
        
        var date = new Date(value);
        var today = new Date();
        
        var startString = "";
        var format = "HH:mm";
        
        //Se è stato inviato oggi
        if(date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            startString = LocalizationServices.getLocalizedValue("app_Oggi", "Oggi", ", ");
        }
        //Se è stato inviato ieri
        else if(date.getDate() == today.getDate() - 1 && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            startString = LocalizationServices.getLocalizedValue("app_ieri", "Ieri", ", ");
        }
        //se è stato inviato questa settimana
        else if(date.getDate() >= today.getDate() - 6 && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            format = "dddd, HH:mm";
        } else {
            format = "DD/MM/YYYY, HH:mm";
        }

        return startString + moment(value).format(format);
    },

    time(value: any, _format: string = "") {
        if (value) {
            let format;
            if(!_format){
                format = 'HH:mm';
            } else {
                format = _format;
            }

            return moment(value).locale("it-IT").format(format);
        }
    },
    
    chatTime(value: any) {
        if (value) {
            let format;
            format = 'DD/MM/YYYY';
            format += ' HH:mm:ss';
            return moment(value).locale('it').format(format);
        }
    },

    currency(value: any, decimals: number = 2) {
        if(!decimals && decimals != 0)
            decimals = 2;
        if (value || value == 0) {
            let val = (value/1).toFixed(decimals).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    },

    previewFix(preview: string){
        if(!preview)
            return "";

        var start = preview.substring(0, 5);

        if(start.indexOf("/img/") == -1)
            return preview;

        if(window.cordova && start.indexOf("./img") == -1)
            return "." + preview;
        else if(!window.cordova && start.indexOf("./img") != -1)
            return preview.replace("./img", "/img");

        return preview;
    }
}