import moment from 'moment';
import { selfUrl } from './config';
import router from './router';
import { StorageServices } from './services/StorageServices';
import store from './store';



export function makeFilePreview(file): Promise<string>{

    // console.log("File after attachment: ");
    // console.log(file);
    
    return new Promise<string>((resolve, reject) => {
        var fileReader = new FileReader();
        if (file.type.match('image')) {
            fileReader.onload = function() {
                var img = document.createElement('img');
                resolve(<any>fileReader.result);
            };
            fileReader.readAsDataURL(file);
        } else if(file.type.match('video')) {
            resolve('/img/icon_video.svg');

            // const canvas = document.createElement("canvas");
            // const video = document.createElement("video");
        
            // // this is important
            // video.autoplay = true;
            // video.muted = true;
            // (<any>video).playsInline = true;
            
            // var buffers = file.arrayBuffer();
            // var blob = new Blob([new Uint8Array(buffers)], { type: 'video/mp4' })
            // video.src = URL.createObjectURL(blob);
        
            // video.onloadeddata = () => {
            //     let ctx = canvas.getContext("2d");
        
            //     canvas.width = video.videoWidth;
            //     canvas.height = video.videoHeight;
        
            //     ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            //     video.pause();
            //     return resolve(canvas.toDataURL("image/png"));
            // };


            // console.log("Type video");
            // fileReader.onload = function() {
            //     var blob = new Blob([fileReader.result], {type: file.type});
            //     var url = URL.createObjectURL(blob);
            //     var video = document.createElement('video');
            //     var timeupdate = function() {
            //         if (snapImage()) {
            //             video.removeEventListener('timeupdate', timeupdate);
            //             video.pause();
            //         }
            //     };
            //     video.addEventListener('loadeddata', function() {
            //         if (snapImage()) {
            //             video.removeEventListener('timeupdate', timeupdate);
            //         }
            //     });
            //     var snapImage = function() {
            //         var canvas = document.createElement('canvas');
            //         canvas.width = video.videoWidth;
            //         canvas.height = video.videoHeight;
            //         canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            //         var image = canvas.toDataURL();
            //         var success = image.length > 100000;
            //         if (success) {
            //             URL.revokeObjectURL(url);
            //             resolve(image);
            //         } else {
            //             reject();
            //         }
            //         return success;
            //     };
            //     video.addEventListener('timeupdate', timeupdate);
            //     video.preload = 'metadata';
            //     video.src = url;
            //     // Load video in Safari / IE11
            //     video.muted = true;
            //     (<any>video).playsInline = true;
            //     video.play();
            // };
            // fileReader.readAsArrayBuffer(file);
        } else if(file.type.match('pdf')) {
            resolve('/img/icon_pdf.svg');
        } else if(file.type.match('ppt') || file.type.match('application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
            resolve('/img/icon_ppt.svg');
        } else if(file.type.match('audio')) {
            resolve('/img/icon_audio.svg');
        } else if(file.type.match('application/vnd.ms-excel') || file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            resolve('/img/xls.png');
        } else if(file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file.type.match('application/msword')){
            resolve('/img/icon_word.svg');
        };
    })
}

export function shuffleArray(list: any[]): any[]{
    var ris = list
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

    return ris;
}

export function getUniversalISOStringDate(input: Date): any {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

    return data.toISOString();
}

export function getFileExtension(mimeType) {
    const extensionMapping = {
        'image/jpeg': '.jpg',
        'image/png': '.png',
        'application/pdf': '.pdf',
        'application/vnd.ms-excel': '.xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'application/msword': '.doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        'application/vnd.ms-powerpoint': '.ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
        // aggiungi altri MIME types e le loro estensioni qui
    };

    return extensionMapping[mimeType] || '';
}

export function appendExtensionIfMissing(filename, mimetype) {
    const mimeType = mimetype; // ottenere il MIME type dal file
    const extension = getFileExtension(mimeType);

    // Controllare se il filename ha già un'estensione
    if (filename.includes('.')) {
        return filename; // restituire il filename originale se ha già un'estensione
    } else {
        return filename + extension; // altrimenti, aggiungere l'estensione
    }
}

// export function getUniversalDate(input: any): Date {

//     if(!input)
//         return null;
//     var data = input;

//     if(typeof input == typeof "")
//         data = new Date(input);

//     data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

//     return data;
// }

export function getUniversalDate(input) {
    if (!input)
        return null;
    var data = input;

    if (typeof input === "string")
        data = new Date(input);

    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());
    
    return data;
}

export function CalculateAgeFromDate(date: Date) : number {
    var today = new Date();

    var age = today.getFullYear() - date.getFullYear();
    var m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
        age--;
    }

    return age;
}

export function fixUrlShare(){
    var url = location.href;

    if(url.indexOf("&hashtag=") > -1){
        var hashtagIndex = url.indexOf("&hashtag=");
        var openAppIndex = url.indexOf("?open_app_uose");
        
        var toReplace = url.substring(hashtagIndex, openAppIndex);
        var newUrl = "/" + location.href.substr(location.href.indexOf("#")).replace(toReplace, "").replace("%3D", "=").replace("%3F", "?");
        
        if(newUrl.split("?").length - 1 > 1)
            newUrl = newUrl.replace("?open_app_uose", "&open_app_uose");

        setTimeout(() => {
            window.location.href = newUrl;
        }, 300);
    }
}

export function checkPassword(p){
    var anUpperCase = /[A-Z]/;
    var aLowerCase = /[a-z]/; 
    var aNumber = /[0-9]/;
    var aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
    if(p.length < 6){
        return false;
    }
    var numUpper = 0;
    var numLower = 0;
    var numNums = 0;
    var numSpecials = 0;
    for(var i=0; i<p.length; i++){
        if(anUpperCase.test(p[i]))
            numUpper++;
        else if(aLowerCase.test(p[i]))
            numLower++;
        else if(aNumber.test(p[i]))
            numNums++;
        else if(aSpecial.test(p[i]))
            numSpecials++;
    }

    if(numUpper == 0 || numLower == 0 || numNums == 0){
        return false;
    }
    return true;
}

export function getCaretPosition (node) {
    var range = window.getSelection().getRangeAt(0);
    var preCaretRange = range.cloneRange();
    var tmp = document.createElement("div");

    preCaretRange.selectNodeContents(node);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    tmp.appendChild(preCaretRange.cloneContents());
    var ris = decodeHtml(tmp.innerHTML).length;
    return ris;
}

export function setCaretLast(el) {
    var range = document.createRange();
    var sel = window.getSelection();
    range.setStart(el.childNodes[el.childNodes.length - 1], el.childNodes[el.childNodes.length - 1].textContent.length - 1);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    el.focus();
}

export function delinkify(text){
    var div = document.createElement('div');
    div.innerHTML = text;
    return div.textContent;
}
export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function getWeek(language: string = "it"): Date[]{
    if(language == "it")
        return getEuropeWeek();
    return [];
}
function getEuropeWeek(): Date[]{
    var date = new Date();
    var ris = [];

    while(date.getDay() != 1)
        date = new Date(date.setDate(date.getDate() - 1));

    while(date.getDay() != 1 || ris.length == 0){
        ris.push(new Date(date));
        date = new Date(date.setDate(date.getDate() + 1));
    }

    return ris;
}
export function getOneLetterWeekDays (language: string = "it"): any[] {
    var ris = [];
    var week = getWeek();

    week.forEach( x => {
        var obj = {
            key: x.getDay(),
            value: moment(x).locale(language).format("dd").substr(0,1)
        };
        ris.push(obj);
    })
    
    return ris;
}

export function getFileFromUri(uri: string): Promise<File>{
    if(uri.indexOf('://') === -1) 
        uri = "file://" + uri;

    let prom = new Promise<File>((resolve, reject) => {
        (<any>window).resolveLocalFileSystemURL(uri, (fileEntry) => {
            fileEntry.file((resFile) => {
                resolve(resFile);
            });
        }, err => {
            console.log(err)
        })
    });

    return prom;
}