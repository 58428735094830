
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class UserProfileImage extends Vue {

    @Prop({
        default: ""
    }) src: string;

    localSrc: string = "";

    created() {
        this.srcChanged();
    }

    @Watch("src")
    srcChanged() {
        this.localSrc = this.src;
    }

    get getImage(){
        if(!this.localSrc)
            return "./img/profile_dark.svg";

        return this.localSrc;
    }
}

