
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class MiniSpinner extends Vue {

    @Prop({
        default: ""
    }) cssClass: string;

}

