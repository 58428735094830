import router from "@/router";
import VueGtag from "vue-gtag";
import Vue from 'vue';
import * as firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export class FirebaseWeb {

    static initFirebase(config: any){
        window.firebase = firebase;

        // Initialize Firebase
        const app = initializeApp(config);
        const auth = getAuth(app);
    }

    static getPlatform(): string {
        if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) 
            return "Windows 10";
        else if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) 
            return "Windows 8";
        else if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) 
            return "Windows 7";
        else if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) 
            return "Windows Vista";
        else if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) 
            return "Windows XP";
        else if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) 
            return "Windows 2000";
        else if (window.navigator.userAgent.indexOf("Mac") != -1) 
            return "Mac/iOS";
        else if (window.navigator.userAgent.indexOf("X11") != -1) 
            return "UNIX";
        else if (window.navigator.userAgent.indexOf("Linux")!= -1) 
            return "Linux";
        else 
            return "Unknown";
    }

    static sendAnalytics(analytics_id_UA: string, app_name: string, platform: string): void {
        if(!analytics_id_UA)
            return;

        //TODO: refactor
        // Vue.use(VueGtag, {
        //     config: { id: analytics_id_UA },
        //     app_name: app_name,
        //     platform: platform
        // }, router);
    }
}