
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FileInput from '@/components/fileInput.vue';
import { appendExtensionIfMissing, getFileFromUri } from '@/utils';

@Options({
    components: {
        FileInput
    }
})
export default class FileSelector extends Vue {

    @Prop() modelValue: any;
    @Prop({
        default: ""
    }) accept: string;

    @Prop({
        default: 0
    }) mediaType: number;

    isCordova: boolean = false;
    file: File = null;

    created(){
        if(window.cordova){
            this.isCordova = true;
        } else {
            this.isCordova = false;
        }
    }

    fileInput: any;
    mounted(){
        setTimeout(() => {
            this.fileInput = this.$refs.fileInput;
        }, 0)
    }

    @Watch('file', { deep: true })
    onFileChange(next, prev) {
        this.$emit('update:modelValue', next);
    }

    selectFile(){
        if(!this.isCordova){
            this.fileInput.$el.click();
        } else {
            var view = this;
            let onSuccess = (imgData: any) => {
                // StatusBar.overlaysWebView(false);

                getFileFromUri(imgData)
                .then( x => {
                    (x as any).name = appendExtensionIfMissing(x.name, x.type);
                    this.$emit('update:modelValue', x);
                })
                // store.state.showSpinner = true;
                // UserServices.UploadUserImageFromUri(imgData, this.user.identifier)
                // .then(x => {
                //     store.state.userImageTimestamp = Date.now();
                //     this.userImage = store.state.getUserImage(store.state.user.identifier);
                // })
                // .finally(() => store.state.showSpinner = false);
            }
            let onError = (err: any) => {
                // StatusBar.overlaysWebView(false);
                console.log(err);
            }
            if(window.cordova){
                (<any>window).navigator.camera.getPicture(onSuccess, onError, {
                    destinationType: window.navigator.camera.DestinationType.FILE_URI,
                    sourceType: window.navigator.camera.PictureSourceType.PHOTOLIBRARY,
                    correctOrientation: true,
                    cameraDirection: 1,
                    allowEdit: true,
                    mediaType: this.mediaType
                })
            }
        }
    }

}
