import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ln_12 text-center d-block w-75 m-auto mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("span", _hoisted_1, [
      _createVNode(_component_localized_text, {
        localizedKey: "app_proseguendoAccetti:",
        text: "Proseguendo accetti:",
        class: "fs_12 ln_12"
      })
    ]),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.documents, (doc) => {
      return (_openBlock(), _createBlock("span", {
        class: "underlined pointer ln_12 text-center d-block w-75 m-auto mt-1 fs_12",
        innerHTML: doc.documentName,
        onClick: ($event: any) => (_ctx.$store.state.openBlank(doc.urlDocument))
      }, null, 8, ["innerHTML", "onClick"]))
    }), 256))
  ], 64))
}