
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ValForm extends Vue {

    submitWrapper(ev){
        let untoucheds = ev.target.querySelectorAll('.v-untouched');
        untoucheds.forEach(x => {
            x.classList.remove('v-untouched');
        })

        let invalids = ev.target.querySelectorAll('[class*="v-invalid"]');
        if(invalids.length > 0){
            this.$emit('invalid');
            return;
        }
        this.$emit('next');
    }
}
