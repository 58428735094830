import { StorageServices } from '@/services/StorageServices';
import { CommonServices } from './CommonServices';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { Vue, VueConstructor } from 'vue-class-component';
import InfoModal from '@/components/modals/infoModal.vue';
import GeolocationTimeOutModal from '@/components/modals/geolocationTimeOutModal.vue';
import { AuthClient, LocalizedClient } from './Services';

class _LocalizationServices {

    constructor() {}

    SetLanguage(language: string) {
        var loggedUser = StorageServices.getLoggedUser();

        if(loggedUser.identifier) {
            AuthClient.changeLanguageAndGetToken(language, false)
            .then( x => {
                StorageServices.setPreferredCulture(language);
                store.state.preferredCulture = language;
                CommonServices.setAuthToken(x);
            }).catch( err => {
                StorageServices.setPreferredCulture(language);
                store.state.preferredCulture = language;
            })
        }
        else {
            StorageServices.setPreferredCulture(language);
            store.state.preferredCulture = language;
            location.reload();
        }
    }
    
    GetLanguage() {
        return store.state.preferredCulture;
    }
    
    getLocalizedValue(key: string, value: string, afterText: string = '', prevText: string = '', object: any = null): string {
        if(store.state.consts.localizedValues.length == 0){
            var result = prevText + value + afterText;
            return this.replaceText(result, object);
        }

        var existing = store.state.consts.localizedValues.find(x => x.key == key);

        if (existing) {
            var selectedCulture = store.state.preferredCulture;
            var result = prevText + existing.translations[selectedCulture] + afterText;
            return this.replaceText(result, object);
        } else {
            var newValue = new OM.KeyValuePairOfStringAndString();
            newValue.key = key;
            newValue.value = value;
            
            LocalizedClient.saveLocalized(newValue, false)
            .then(x => {
                store.state.consts.localizedValues.push(x);
            })
            
            var result = prevText + value + afterText;
            return this.replaceText(result, object);
        }
    }

    getTextFromValues(value: OM.LocalizedText[], customLang: string = "") {
        var lang = StorageServices.getPreferredCulture();
        if (customLang)
            lang = customLang;

        var found = value.find( x => x.cultureName == lang);
        var foundDefault = value.find( x => x.cultureName == VM.LanguageList.eng)
        
        if (!found && foundDefault)
            return foundDefault.text;
        else if(!found)
            return ""

        return found.text;
    }

    getTextFromLocalizedValue(model: OM.LocalizedValue, customLang: string = "") {
        var values = model.values;

        if (customLang)
            return this.getTextFromValues(values, customLang);

        return this.getTextFromValues(values);
    }

    replaceText(text: string, object: any): string {
        if(!object)
            return text;
        
        while(text.indexOf('{{') != -1){
            var init = text.indexOf("{{");
            var end = text.indexOf("}}");
    
            if(init == -1 || end == -1)
                break;
            
            var textToReplace = text.substring(init, text.length - (text.length - end) + 2);
            var variableName = textToReplace.substring(2, textToReplace.length - 2);
            
            if(object[variableName] == null || object[variableName] == undefined){
                console.error("Variabile: " + variableName + " non esistente nell'oggetto");
                break;
            }
    
            text = text.replace(textToReplace, object[variableName]);
        }
    
        return text;
    }

    getMyPositionText(){
        var key = "app_LaMiaPosizione";
        var text = "La mia posizione";

        return this.getLocalizedValue(key, text);
    }
}

export let LocalizationServices = new _LocalizationServices();