
import { Options, Vue } from 'vue-class-component';
import { LoginServices } from '@/services/LoginServices';
import { AuthClient, UserClient } from '@/services/Services';
import { CommonServices } from '@/services/CommonServices';
import firebase from "firebase/app";
import "firebase/auth";
import ModalSuccessError from '@/components/modalSuccessError.vue';
import ConfirmModal from '@/components/confirmModal.vue';
import store from '@/store';
import * as OM from '@/model';
import * as firebaseauth from 'firebase/auth';
import * as firebaseauthcordova from 'firebase/auth/cordova';
import { signInWithPopup } from 'firebase/auth';
import { getRedirectResult, signInWithRedirect } from 'firebase/auth/cordova';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class SocialLogin extends Vue {

    ios: boolean = false;

    apple: any = null;
    facebook: any = null;
    google: any = null;

    googleClass = null;
    facebookClass = null;
    oauthClass = null;

    auth = null;

    created(){
        if(!window.cordova){
            this.google = new firebaseauth.GoogleAuthProvider();
            this.facebook = new firebaseauth.FacebookAuthProvider();
            this.apple = new firebaseauth.OAuthProvider('apple.com');
            this.apple.addScope('email');
            this.apple.addScope('name');

            this.googleClass = firebaseauth.GoogleAuthProvider;
            this.facebookClass = firebaseauth.FacebookAuthProvider;
            this.oauthClass = firebaseauth.OAuthProvider;
            this.auth = firebaseauth.getAuth();
        } else {
            this.google = new firebaseauthcordova.GoogleAuthProvider();
            this.facebook = new firebaseauthcordova.FacebookAuthProvider();
            this.apple = new firebaseauthcordova.OAuthProvider('apple.com');
            this.apple.addScope('email');
            this.apple.addScope('name');

            this.googleClass = firebaseauthcordova.GoogleAuthProvider;
            this.facebookClass = firebaseauthcordova.FacebookAuthProvider;
            this.oauthClass = firebaseauthcordova.OAuthProvider;
            this.auth = firebaseauthcordova.getAuth();
        }

        if(!window.cordova){
            this.ios = false;
            return;
        }

        if(device.platform == "iOS" && parseInt(device.version) >= 13)
            this.ios = true;
        else
            this.ios = false;            
    }

    setPhase(numberPhase: number){
        this.$emit("setPhase", numberPhase);
    }

    signIn(provider, name, providerClass){
        if(window.cordova)
            this.signInApp(provider, name, providerClass);
        else
            this.signInWeb(provider, name, providerClass);
    }   

    signInApp(provider, name, providerClass){
        var vm = this;
        store.state.globalSpinner++;

        signInWithRedirect(this.auth, provider)
        .then(() => {
            return getRedirectResult(this.auth);
        })
        .then(function(result) {
            const user = result.user;
            var displayName = user.displayName;
            const credential = providerClass.credentialFromResult(result);
            const accessToken = vm.getToken(credential, name);

            vm.afterSocial(name, accessToken, displayName);
            store.state.globalSpinner--;
            
        }).catch(function(error) {
            console.log(error)
            store.state.globalSpinner--;

        });
    }

    signInWeb(provider, name, providerClass){
        var vm = this;
        store.state.globalSpinner++;
        signInWithPopup(vm.auth, provider)
        .then(function(result) {
            const user = result.user;
            var displayName = user.displayName;
            const credential = providerClass.credentialFromResult(result);
            const accessToken = vm.getToken(credential, name);

            vm.afterSocial(name, accessToken, displayName);
            store.state.globalSpinner--;
            
        }).catch(function(error) {
            console.log(error)
            store.state.globalSpinner--;

        });
    }

    getToken(credential, name): string {
        console.log("CREDENTIAL", credential, name)
        console.log(credential)
        if(name == "facebook")
            return credential.accessToken
        else if(name == "google")
            return credential.idToken;
        else if(name == "apple")
            return credential.idToken;
    }

    afterSocial(name: string, token: string, displayName: string){
        AuthClient.redirectAccessToken2(name, token, displayName)
        .then( x => {
            let loginResponse: OM.LoginResponse = {
                token: x.token,
                userIdentifier: x.identifier
            }

            CommonServices.setAuthToken(x.token);
            CommonServices.showSpinnerCb();
            LoginServices.loginCallback(loginResponse, this)
            .finally( () => {
                CommonServices.hideSpinnerCb();
            });
        })
        .catch( error => {
            this.$opModal.show(ModalSuccessError, { 
                isError: true,
                message: error.message, 
            });
        })
    }
}
