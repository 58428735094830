import { createApp } from 'vue';
import { initFirebase, sendAnalytics } from './services/Firebase/FirebaseServices';
import App from './App.vue';
import router from './router';
import store from './store';
import modalPlugin from './plugins/modalPlugin';
import FTS from '@/filters';
import * as DIRECTIVES from '@/directives';

import LocalizedText from '@/components/localizedText.vue';
import MiniSpinner from '@/components/miniSpinner.vue';
import ValForm from '@/components/valForm.vue';
import UserProfileImage from '@/components/userProfileImage.vue';
import FileSelector from '@/components/fileSelector.vue';
import TextareaAutosize from '@/components/textareaAutosize.vue';
import PreviewPostAttachment from '@/components/previewPostAttachment.vue';

import 'swiper/swiper-bundle.min.css';
import '@/assets/css/style.css';
import '@/assets/css/font.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CommonServices } from '@/services/CommonServices';
import { LoginServices } from './services/LoginServices';
import { LocalizationServices } from './services/LocalizationServices';

const app = createApp(App)
.use(store)
.use(router)
    .use(modalPlugin)
    .component('localized-text', LocalizedText)
    .component('MiniSpinner', MiniSpinner)
    .component('ValForm', ValForm)
    .component('userProfileImage', UserProfileImage)
    .component('fileSelector', FileSelector)
    .component('textarea-autosize', TextareaAutosize)
    .component('preview-post-attachment', PreviewPostAttachment)
    .directive("required", DIRECTIVES.required)
    .directive("date", DIRECTIVES.date)
    .directive("localizedPlaceholder", DIRECTIVES.localizedPlaceholderDirective)
    .directive("userimage", DIRECTIVES.userimage);
    
app.config.globalProperties.$filters = FTS;
app.config.globalProperties.$localizationService = LocalizationServices;

document.addEventListener("deviceready", () => {
    window.open = window.cordova.InAppBrowser.open;
    initFirebase();
});
// questo codice riporta sempre i query params. ma li riporta all'infinito. brutto.
// router.beforeEach((to, from, next) => {
//     if(from.query){
//         to.query = {
//             ...from.query,
//             ...to.query
//         };
//     }
//     next();
// })

CommonServices.unauthorizedCb = () => {
    setTimeout( () => {
        let queryParams = null;
        if(router.currentRoute.value.path != '/' && 
            router.currentRoute.value.name != 'welcome' &&
            router.currentRoute.value.name != 'login' &&
            router.currentRoute.value.name != 'register'){
            
            queryParams = {
                redirect: router.currentRoute.value.fullPath
            }
        }
        LoginServices.logout(queryParams);
    }, 0);
}

app.mount('#app');