import { StorageServices } from './services/StorageServices';
import store from '@/store';
import * as OM from '@/model';
import { LocalizedClient } from './services/Services';
import { LocalizationServices } from './services/LocalizationServices';

function directiveBindFn(el, binding, vnode, regExpr: RegExp, classSuffix: string){
    if(binding.value != undefined && !binding.value)
        return;

    el.classList.add('v-untouched');
    let vmodelIndex = vnode.data.directives.findIndex(x => x.name == 'model');
    let vmodelValue = vnode.data.directives[vmodelIndex].value;
    //if(vmodelValue == undefined || vmodelValue == null || vmodelValue == '')
    //    return;
    if(checkFunction(vmodelValue, regExpr)){
        el.classList.add('v-valid-' + classSuffix);
    } else {
        el.classList.add('v-invalid-' + classSuffix);
    }
}
function directiveUpdateFn(el, binding, vnode, regExpr: RegExp, classSuffix: string){
    let vmodelIndex = vnode.data.directives.findIndex(x => x.name == 'model');
    let vmodelDirective = vnode.data.directives[vmodelIndex];
    if(vmodelDirective.oldValue === undefined || vmodelDirective.value === vmodelDirective.oldValue)
        return;

    el.classList.remove('v-untouched');

    el.classList.remove('v-invalid-' + classSuffix);
    el.classList.remove('v-valid-' + classSuffix);
    if (binding.value != undefined && !binding.value) {
        return;
    }

    let vmodelValue = vmodelDirective.value;
    if(checkFunction(vmodelValue, regExpr)){
        el.classList.add('v-valid-' + classSuffix);
    } else {
        el.classList.add('v-invalid-' + classSuffix);
    }
}
function checkFunction(val, regExp){
    if(val === null || val === undefined)
        return false;
    val = val.toString().trim();
    if(!regExp){
        return !!val;
    } else {
        if(regExp.test(val)){
            return true;
        } else {
            return false;
        }
    }
}

export let required = {
    mounted(el, binding, vnode) {
        directiveBindFn(el, binding, vnode, null, 'required'); 
    },
    updated(el, binding, vnode) {
        directiveUpdateFn(el, binding, vnode, null, 'required');
    }
}

export let number = {
    beforeMount(el, binding, vnode) {
        let regExp = new RegExp(/^(-?\d+\.\d+)$|^(-?\d+)$/gm)
        directiveBindFn(el, binding, vnode, regExp, 'number'); 
    },
    updated(el, binding, vnode) {
        let regExp = new RegExp(/^(-?\d+\.\d+)$|^(-?\d+)$/gm)
        directiveUpdateFn(el, binding, vnode, regExp, 'number');
    }
}

export let userimage = {
    beforeMount(el, binding, vnode) {
        var img = document.createElement("img");
        img.onerror = (x) => {
            el.style.backgroundImage = "url(./img/profile_dark.svg)";
        }
        el.style.backgroundImage = "url('" + binding.value + "')";
        img.src = binding.value;
    }
}

export let telephoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
export let telephone = function(el, binding, vnode, prevVnode){
    el.classList.remove('v-invalid-telephone');
    el.classList.remove('v-valid-telephone');

    let vmodelIndex = vnode.data.directives.findIndex(x => x.name == 'model');
    let vmodelValue = vnode.data.directives[vmodelIndex].value;
    if(el.tagName.toLowerCase() != 'input' && el.tagName.toLowerCase() != 'select'){
        console.error('v-telephone directive is not on tag input or select, but on tag ' + el.tagName + ". Se è una textarea aggiusta la cosa");
        return;
    }

    let found = vmodelValue.match(telephoneRegex);
    if(!found){
        el.classList.add('v-invalid-telephone');
    }
}

export let date = function(el, binding, vnode, prevVnode) {
    if(el.tagName.toLowerCase() != 'input'){
        console.error('v-date directive must be used on input tags. use type="date"');
        return;
    }
    if(el.getAttribute('type') != 'date'){
        console.error('v-date directive must be used on input with type="date"');
        return;
    }

    let modelDirectiveIndex = vnode.dirs.findIndex(x => x.value && (typeof(x.value) == typeof(new Date()) || isDate(x.value)));
    if(modelDirectiveIndex == -1){
        console.error('v-date directive requires v-model on element');
        return;
    }
    let val = vnode.dirs[modelDirectiveIndex].value;
    if(val){
        (<HTMLInputElement>el).value = validateDate(val);
        var item = (<HTMLInputElement>el).attributes.getNamedItem("placeholder");

        if(item){
            item.value = "";
            (<HTMLInputElement>el).attributes.setNamedItem(item);
        }
    }
}

function isDate(date) {
    var tempDate = JSON.parse(JSON.stringify(date))
    return (new Date(tempDate) as any !== "Invalid Date") && !isNaN(new Date(tempDate) as any);
}
// export let date = function(el, binding, next, vnode, prevVnode){
//     if(el.tagName.toLowerCase() != 'input'){
//         console.error('v-date directive must be used on input tags. use type="date"');
//         return;
//     }
//     if(el.getAttribute('type') != 'date'){
//         console.error('v-date directive must be used on input with type="date"');
//         return;
//     }
//     let modelDirectiveIndex = next.data.directives.findIndex(x => x.name == 'model');
//     if(modelDirectiveIndex == -1){
//         console.error('v-date directive requires v-model on element');
//         return;
//     }
//     let val = next.data.directives[modelDirectiveIndex].value;
//     if(val){
//         (<HTMLInputElement>el).value = validateDate(val);
//     }
// }
function validateDate(inputDate: string){
    let date = new Date(inputDate);
    if(isValidDate(date)){
        let years = date.getFullYear().toString();
        while(years.length < 4){
            years = "0" + years;
        } 
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;
        return years +  "-" + months + "-" + days;
    }
}
function isValidDate(d: any) {
    return d instanceof Date && !isNaN(<any>d);
}

export let localizedPlaceholderDirective = function (el, binding, vnode, prevVnode) {

    var key = binding.value["key"];
    var value = binding.value["value"];

    if (!key || !value) {
        console.error('v-localizedPlaceholder need key and value');
        return;
    }

    var prevText = binding.value["prevText"];
    var afterText = binding.value["afterText"];

    var text = LocalizationServices.getLocalizedValue(key, value, afterText, prevText);

    (<any>el).placeholder = text;
}