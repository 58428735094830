import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/usercalendar/:day?',
            name: 'usercalendar',
            meta: { clearCache: true },
            component: () => import('./views/usercalendar.vue'),
        },
        {
            path: '/usercalendar/day/:day',
            name: 'usercalendar day',
            meta: { clearCache: true },
            component: () => import('./views/dayEvents.vue'),
        },
        {
            path: '/profile/:identifier',
            name: 'profile',
            meta: { clearCache: true },
            component: () => import('./views/profile.vue'),
        },
        // {
        //     path: '/profile/:identifier/places',
        //     name: 'profile',
        //     meta: { clearCache: true },
        //     component: () => import('./views/places.vue'),
        // }
    ];
};