import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/chats',
            name: 'chats',
            component: () => import('./views/chats.vue'),
        },
        {
            path: '/chats/social/search/',
            name: 'chat_search_faux',
            meta: { hideFooter: true },
            redirect: '/chats/social/search/Chat',
        },
        {
            path: '/chats/social/search/:tab',
            name: 'chat_search',
            meta: { hideFooter: true },
            component: () => import('./views/socialSearch.vue'),
        },
        {
            path: '/chats/:identifier',
            name: 'chat_base',
            meta: { hideFooter: true },
            component: () => import('./views/chatBase.vue'),
        },
        {
            path: '/chats/detail/:identifier',
            name: 'chat_room_detail',
            component: () => import('./views/chatRoomDetail.vue'),
        },
        {
            path: '/chats/newRequest/:userIdentifier',
            name: 'chat_newRequest',
            meta: { hideFooter: true },
            component: () => import('./views/chatNewRequest.vue'),
        },
        {
            path: '/chats/request/:identifier',
            name: 'chat_request',
            meta: { hideFooter: true },
            component: () => import('./views/chatRequest.vue'),
        },
    ];
};