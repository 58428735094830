import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/categories/:hasBackButton',
            name: 'categories',
            component: () => import('./views/categories.vue'),
        },
    ];
};