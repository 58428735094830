import { createStore } from 'vuex'
import { StorageServices } from '@/services/StorageServices';
import { baseUrl } from '@/config';
import * as CONST from '@/const';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import router from '@/router';

let store = {
    state: {
        user: <OM.AppUserVm>null,
        loginResponse: StorageServices.getLoginResponse() || null,
        isAdmin: () => {
            return store.state.user.role == CONST.AppRole.Admin;
        },
        isManager: () => {
            return store.state.user.role == CONST.AppRole.Manager;
        },
        community: <OM.CommunityDetailVm>null,
        fiera: <OM.FieraDetailVm>null,
        chatRoomAllowances: <OM.ChatRoomAllowanceVm>null,
        pallinoNotification: 0,
        globalSpinner: 0,
        cacheBusted: {},
        consts: {
            localizedValues: <OM.LocalizedEntityVM[]>[],
        },
        preferredCulture: "",
        selectedTagFromSearch: <OM.ForumTag>null,
        cacheBuster: (url) => {
            let ris = url;
            if(store.state.cacheBusted[url]){
                store.state.cacheBusted[url]++;
            } else {
                store.state.cacheBusted[url] = 1;
            }
            ris += '?r=' + store.state.cacheBusted[url];
            return ris;
        },
        userImageRefresher: {},
        getUserImage: (identifier: string) => {
            let ris = baseUrl + "content/" + identifier + ".jpg";
            if(store.state.userImageRefresher[identifier])
                ris += '?r=' + store.state.userImageRefresher[identifier];
            return ris;
        },
        selectedUser: StorageServices.getSelectedUser() || null,
        createCommunityVm: <VM.CreateCommunityVm> new VM.CreateCommunityVm(),
        createEventVm: <VM.CreateEventVm> new VM.CreateEventVm(),
        newNotificationCount: 0,
        newInvitationCount: 0,
        checkNotificationFunction: null,
        newChatMessages: <string[]>[],
        query: "",
        notification: null,
        notificationFunction: () => {},
        notificationDrawerClick(){
            this.$store.state.notification = null;
            this.notificationFunction();
        },
        allPeoplePage: {
            model: <OM.NameIdentifier[]>[],
            title: ''
        },
        openBlank: (url: string) => {
            if(window.cordova)
                window.cordova.InAppBrowser.open(url, '_system');
            else
                window.open(url, "_blank")
        }
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
    getters: {
    }
}

if(!store.state.preferredCulture)
    store.state.preferredCulture = StorageServices.getPreferredCulture();

if(!store.state.user)
    store.state.user = StorageServices.getLoggedUser();

export default createStore(store);