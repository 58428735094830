import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("input", {
    type: "file",
    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileSelect && _ctx.onFileSelect(...args))),
    accept: _ctx.accept,
    ref: "myFiles"
  }, null, 40, ["accept"]))
}