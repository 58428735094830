import { CommonServices } from "@/services/CommonServices";
import { StorageServices } from '@/services/StorageServices';
import { AfterLoginMandatoryDocumentClient, UserClient } from '@/services/Services';
import store from '@/store';
import router from '@/router';
import * as OM from '@/model';
import MandatoryDocumentsAfterLoginModal from '@/components/mandatoryDocumentsAfterLoginModal.vue';

class _LoginServices {

    loginCallback = (loginResponse: OM.LoginResponse, view, navigate: boolean = true) => {
        return new Promise<void>((resolve, reject) => {
            if(loginResponse && loginResponse.token)
                CommonServices.setAuthToken(loginResponse.token);
            
            if(loginResponse && loginResponse.userIdentifier)
                StorageServices.setUserIdentifier(loginResponse.userIdentifier);
            
            this.checkMandatoryDocuments(view)
            .then( () => {
                CommonServices.showSpinnerCb();

                UserClient.getMe()
                .then(x => {
                    if(window.cordova){
                        FirebasePlugin.hasPermission(function(hasPermission){
                            if(hasPermission)
                                RegisterPushNotification();
                            else
                                FirebasePlugin.grantPermission(function(hasPermission){

                                    if(hasPermission)
                                        RegisterPushNotification();
                                })
                        });
                        FirebasePlugin.setUserId(x.identifier);
                    }

                    store.state.user = x;
                    StorageServices.setLoggedUser(store.state.user);
                    
                    if(navigate){
                        if(!store.state.user.profileCompleted){
                            router.replace({
                                path: '/completeprofile',
                                query: router.currentRoute.value.query
                            });
                        } else if(store.state.user.firstSetupDone){
                            if(router.currentRoute.value.query.redirect){
                                let redirectTo = router.currentRoute.value.query.redirect as string;
                                router.replace('/home')
                                .then(x => {
                                    router.push(redirectTo);
                                })
                            } else {
                                let routeLink = StorageServices.getRouteLink();
                                StorageServices.clearRouteLink();
                                if(routeLink){
                                    router.replace('/home')
                                    .then(x => {
                                        router.push(routeLink);
                                    })
                                } else {
                                    router.replace("/home");
                                }
                            }
                        } else {
                            router.replace({
                                path: '/categories/false',
                                query: router.currentRoute.value.query
                            });
                        }
                    }
                    resolve();
                })
                .catch(err => {
                    reject(err);
                    if(navigate && err){
                        router.replace('/welcome');
                    }
                }).finally( () => {
                    CommonServices.hideSpinnerCb();
                })
            }).catch( () => {
                this.logout();
            })

        })
    }

    checkMandatoryDocuments(view: any): Promise<void> {
        return new Promise((resolve, reject) => {
            AfterLoginMandatoryDocumentClient.getMandatoryMustSign()
            .then( x => {
                if(x.length == 0){
                    resolve();
                    return;
                }
                    
                CommonServices.hideSpinnerCb();

                view.$opModal.show(MandatoryDocumentsAfterLoginModal, {
                    documents: x,
                    refuseCallback: () => {
                        view.$opModal.closeLast();
                        reject();
                        return;
                    },
                    acceptCallback: () => {
                        UserClient.acceptAllNewMandatoryDocumentsAfterLogin()
                        .then( () => {
                            view.$opModal.closeLast();
                            resolve();
                        }).catch( () => {
                            reject();
                        })
                    }
                })
            })
        });
    }

    isLoggingOut: boolean = false;

    logout(queryParams?: { [key: string]: string }){
        if(this.isLoggingOut)
            return;

        this.isLoggingOut = true;
        CommonServices.destroyToken();
        StorageServices.setUserIdentifier("");
        StorageServices.setSelectedUser(new OM.NameIdentifier());
        StorageServices.setChatListVmList([]);
        StorageServices.setLoggedUser(new OM.AppUserVm());
        store.state.newNotificationCount = 0;
        store.state.user = new OM.AppUserVm();


        router.push({
            path: '/',
            query: {
                ...router.currentRoute.value.query,
                ...queryParams
            },
            replace: true
        })
        .then(x => {
            this.isLoggingOut = false;
        });
        
        // setTimeout(() => {
        //     store.state.user = null;
        // }, 400);
    }

}

function RegisterPushNotification(){
    // window.cordova.plugins.firebase.messaging.createChannel({
    //     id: "1",
    //     name: "Kpet Update",
    //     badge: true,
    //     vibration: true
    // }).then( x => {
    //     console.log(x)
    // });

    FirebasePlugin.getToken(function(fcmToken) {
        UserClient.setFcmId(fcmToken, false)
    }, function(error) {
        console.log("FIREBASE PLUGIN GET TOKEN ERROR");
        console.log(error)
    });

    // FirebasePlugin.onTokenRefresh(function(fcmToken) {
    //     UserClientWS.setFCMID(fcmToken)
    // }, function(error) {
    //     console.log("FIREBASE PLUGIN ON TOKEN REFRESH ERROR");
    //     console.error(error);
    // });
    
    FirebasePlugin.onMessageReceived(function(message) {
        store.state.newNotificationCount = store.state.newNotificationCount + 1;
        console.log("ECCO IL MESSAGGIO", message)
        if(!message.tap && window.location.href.indexOf(message.route) == -1 ){
            store.state.notification = message;
            store.state.notificationFunction = () => {
                store.state.notification = null;
                goToRoute(message);
            };
        }
        else
            goToRoute(message);
        console.log("New foreground FCM message: ", message);
        // console.log("Message type: " + message.messageType);
        // if(message.messageType === "notification"){
        //     console.log("Notification message received");
        //     if(message.tap){
        //         console.log("Tapped in " + message.tap);
        //     }
        // }
        // console.dir(message);
    }, function(error) {
        console.error(error);
    });
};

function goToRoute(message: any) : void{
    if(message.route){
        var route = message.route;
        console.log("pushed" +  route)

        router.push(route)

    //     if(message.notificationType == CONST.NotificationType.AllegatoComment ||
    //         message.notificationType == CONST.NotificationType.CommunityPostComment ||
    //         message.notificationType == CONST.NotificationType.EventPostComment ||
    //         message.notificationType == CONST.NotificationType.FieraPostComment){
    //         EventBus.$opModal.show(CommentsModal, {
    //             parentIdentifier: message.parentIdentifier,
    //             //grandParentIdentifier: message.grandParentIdentifier,
    //             containerIdentifier: message.containerIdentifier,
    //             commentType: message.notificationType
    //         })
    //     } else if (message.notificationType == CONST.NotificationType.AllegatoCommentComment ||
    //         message.notificationType == CONST.NotificationType.CommunityPostCommentComment ||
    //         message.notificationType == CONST.NotificationType.EventPostCommentComment ||
    //         message.notificationType == CONST.NotificationType.FieraPostCommentComment){
    //         var parentCommentType;
    //         if(message.notificationType == CONST.NotificationType.AllegatoCommentComment)
    //             parentCommentType = CONST.NotificationType.AllegatoComment;
    //         else if(message.notificationType == CONST.NotificationType.CommunityPostCommentComment)
    //             parentCommentType = CONST.NotificationType.CommunityPostComment;
    //         else if(message.notificationType == CONST.NotificationType.EventPostCommentComment)
    //             parentCommentType = CONST.NotificationType.EventPostComment;
    //         else if(message.notificationType == CONST.NotificationType.FieraPostCommentComment)
    //             parentCommentType = CONST.NotificationType.FieraPostComment;
    //         EventBus.$opModal.show(CommentsModal, {
    //             parentIdentifier: message.grandParentIdentifier,
    //             //grandParentIdentifier: message.grandParentIdentifier,
    //             containerIdentifier: message.containerIdentifier,
    //             commentType: parentCommentType
    //         })
    //         EventBus.$opModal.show(CommentsModal, {
    //             parentIdentifier: message.parentIdentifier,
    //             grandParentIdentifier: message.grandParentIdentifier,a
    //             containerIdentifier: message.containerIdentifier,
    //             commentType: message.notificationType
    //         })
    //     }
    
    }
}

export let LoginServices = new _LoginServices();