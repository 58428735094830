
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({})
export default class PreviewPostAttachment extends Vue {

    @Prop() preview: string;
    @Prop({
        default: false
    }) wantDeleteAttachment: boolean;

    get getAdditionalClass(){
        if(!this.preview)
            return "";

        if(this.preview.indexOf(".pdf") != -1)
            return "pdf";
        else if(this.preview.indexOf(".xls") != -1)
            return "xls";
        else if(this.preview.indexOf(".ppt") != -1)
            return "ppt";
        else if(this.preview.indexOf(".doc") != -1)
            return "word";

        return "";
    }

}

