import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/fbToken',
            name: 'fbToken',
            component: () => import('./views/fbToken.vue'),
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: () => import('./views/welcome.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/login.vue'),
        },
        {
            path: '/confirmedCode',
            name: 'confirmedCode',
            component: () => import('./views/confirmedCode.vue'),
        },
        {
            path: '/resetpassword',
            name: 'reset password',
            component: () => import('./views/resetPassword.vue'),
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('./views/register.vue'),
        },
        {
            path: '/completeprofile',
            name: 'complete profile',
            component: () => import('./views/completeProfile.vue'),
        },
    ];
};