import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fullHeightModal hasHeader bgWhite hasFooter" }
const _hoisted_2 = { class: "header withShadow text-center py-4" }
const _hoisted_3 = { class: "px-3 py-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "footerButtonContainer" }
const _hoisted_6 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_mandatory_documents = _resolveComponent("mandatory-documents")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        class: "fs_20 greyBlue",
        localizedKey: "app_aggiornamento",
        text: "Aggiornamento"
      })
    ]),
    _createVNode("div", _hoisted_3, [
      (_ctx.documents.length == 1)
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            _createVNode("span", {
              innerHTML: _ctx.documents[0].phraseToShow
            }, null, 8, ["innerHTML"])
          ]))
        : (_openBlock(), _createBlock(_component_mandatory_documents, {
            key: 1,
            documents: _ctx.documents
          }, null, 8, ["documents"]))
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode("button", {
          type: "button",
          class: "genericButton bgWhite w-100 me-1",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.refuseCallback()))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_14 spaceCadet",
            localizedKey: "app_rifiuta",
            text: "Rifiuta"
          })
        ]),
        _createVNode("button", {
          type: "button",
          class: "genericButton bgGreyBlue w-100 ms-1",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.acceptCallback()))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_14 white",
            localizedKey: "app_accetta",
            text: "Accetta"
          })
        ])
      ])
    ])
  ]))
}