
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ConfirmModal extends Vue {

    @Prop() text: string;
    @Prop() okCb: () => void;
    @Prop() koCb: () => void;
    @Prop() textClickedCb: () => void;

    @Prop({
        default: "NO"
    }) noButton: string;

    @Prop({
        default: "SI"
    }) yesButton: string;

    ok() {
        if(this.okCb)
            this.okCb();
        else
            this.$emit('close');
    }
    ko() {
        if(this.koCb)
            this.koCb();
        else
            this.$emit('close');
    }

    textClicked() {
        if(this.textClickedCb)
            this.textClickedCb();
    }
}

