import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/communities-feed',
            name: 'communities feed',
            component: () => import('./views/communitiesFeed.vue'),
        },
        // {
        //     path: '/communities-intro',
        //     name: 'communities intro',
        //     component: () => import('./views/communitiesIntro.vue'),
        // },
        // {
        //     path: '/communities',
        //     name: 'communities_faux',
        //     redirect: '/communities/menu/Seguite',
        //     component: () => import('./views/communities.vue'),
        // },
        // {
        //     path: '/communities/menu/:tab',
        //     name: 'communities',
        //     component: () => import('./views/communities.vue'),
        // },
    ];
};