import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import { splashModule } from '@/views/splash';
import { loginModule } from '@/views/login';
import { categoriesModule } from '@/views/categories';
import { homeModule } from '@/views/home';
import { profileModule } from '@/views/profile';
import { notificationModule } from '@/views/notification';
import { chatModule } from '@/views/chat';
import { communitiesModule } from '@/views/communities';
import { communityModule } from '@/views/community';
import { ordersModule } from '@/views/orders';
import { peopleModule } from '@/views/people';
import { fieraModule } from '@/views/fiera';
import { fieraLandingModule } from '@/views/fieraLanding';

const routes: Array<RouteRecordRaw> = [
    ...splashModule.routes(),
    ...loginModule.routes(),
    {
        path: '/',
        component: () => import("../views/layout.vue"),
        children: [
            ...categoriesModule.routes(),
            ...fieraLandingModule.routes(), 
            {
                path: '/',
                component: () => import("../views/menuLayout.vue"),
                children: [
                    ...homeModule.routes(),
                    ...profileModule.routes(),
                    ...notificationModule.routes(),
                    ...chatModule.routes(),
                    ...communitiesModule.routes(),
                    ...communityModule.routes(),
                    ...peopleModule.routes(),
                    ...fieraModule.routes(),
                ]
            },
            ...ordersModule.routes()
        ]
    },
]

const router = createRouter({
    history: window.cordova ? createWebHashHistory() : createWebHistory(),
    routes
})

export let beforeEachCbs = [];
router.beforeEach((to, from, next) => {
    beforeEachCbs.forEach(x => {
        x(to, from);
    })
    next();
})

window.navigateToLink = function(path){
    router.push(path)
}

export default router