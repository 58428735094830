import * as OM from '@/model';

export const validationemailerror = "RequiredValidationEmail";

export class LanguageList
{
    public static readonly ita: string = "it-IT";
    public static readonly eng: string = "en-US";
}

export function getLanguages() {
    return [LanguageList.ita, LanguageList.eng];
}

export var ReportType = {
    Post : "Post",
    Comment : "Comment",
    User : "User",
};

export var SearchResultTypeVm = {
    Community : "Community",
    Event : "Event",
    Fiera : "Fiera",
    Topic : "Topic",
    Post : "Post",
    Shop : "Shop",
};

export class LoginVm
{
    email: string;
    password: string;
}

export class PasswordResetVm
{
    email: string;
    token: string;
    newPassword: string;
}

export class DashboardVm
{
    totalCustomers: number;
    totalSales: number;
    totalProducts: number;
}


export class NameIdentifierSelected extends OM.NameIdentifier {
    selected?: boolean;
}

export class MakeSaleVm
{
    customerIdentifier: string;
    productIdentifier: string;
}

export class SelectableCategoryConfig extends OM.CategoryConfigVm {
    selected?: boolean;
}

export class CreatePostVm
{
    identifier: string;
    containerIdentifier: string;
    title: string;
    text: string;
    attachment: File;
    publishedDate: string;
    isAllegato: boolean;
    postType: string;
    categoryAttachment: CategoryAttachmentVM;
    constructor(){
        this.identifier = "";
        this.containerIdentifier = "";
        this.title = "";
        this.text = "";
        this.attachment = null;
        this.isAllegato = false;
        this.postType = "";
        this.categoryAttachment = new CategoryAttachmentVM();

    }
}

export class CategoryAttachmentVM
{
    id: string ;
    communityIdentifier: string ;
    name: string ;
    
   constructor()
   {
        this.id = "";
        this.communityIdentifier = "" ;
        this.name = "" ;
   }
}


export class UpdatePostVm
{
    identifier: string;
    title: string;
    text: string;
    attachment: File;
    publishedDate: string;
    removeAttachment: boolean;
    categoryAttachment: CategoryAttachmentVM;
    constructor(){
        this.title = "";
        this.text = "";
        this.attachment = null;
        this.publishedDate = null;
        this.removeAttachment = false;
        this.categoryAttachment = new CategoryAttachmentVM();
    }
}

export class CreateForumPostVm extends CreatePostVm
{
    tag: OM.ForumTag;
    
    constructor(){
        super();
        this.containerIdentifier = "";
        this.title = "";
        this.text = "";
        this.attachment = null;
        this.isAllegato = false;
        this.postType = "";
        this.tag = new OM.ForumTag();
    }
}

export class CreateCommunityVm extends OM.CreateCommunityVm
{
    gallery: File[];
    backgroundImage: File;
    logo: File;
    constructor(){
        super();
        
        this.identifier = "" ;
        this.ownerIdentifier = "" ;
        this.isCertified = false ;
        this.xPayApiKey = "" ;
        this.iban = "" ;
        this.transferRecipient = "" ;
        this.shipmentDescription = "" ;
        this.shipmentAmount = 0;
        this.title = "" ;
        this.description = "" ;
        this.permissionSettings = new OM.PermissionSettings();
        this.admins = [];
        this.moderators = [];
        this.tags = [];
        this.category = "" ;
        this.gallery = [];
        this.backgroundImage = null;
        this.logo = null;
        this.communityType = OM.CommunityType.Public;
    }
}

export class CreateEventVm extends OM.CreateEventVm
{
    timeFrom : string;
    timeTo : string;
    gallery: File[];
    backgroundImage: File;
    logo: File;

    constructor(){
        super();
        this.identifier = "" ;
        this.ownerIdentifier = "" ;
        this.isCertified = false ;
        this.xPayApiKey = "" ;
        this.iban = "" ;
        this.transferRecipient = "" ;
        this.shipmentDescription = "" ;
        this.shipmentAmount = 0 ;
        this.admins = [];
        this.moderators = [];
        this.communityIdentifier = "";
        this.dateFrom = <any>new Date();
        this.timeFrom = "12:00"
        this.dateTo = <any>new Date();
        this.timeTo = "12:00"
        this.openFrom = "";
        this.openTo = "";
        this.address = new OM.Address();
        this.title = "";
        this.description = "";
        this.permissionSettings = new OM.PermissionSettings();
        this.gallery = [];
        this.backgroundImage = null;
        this.logo = null;
        this.tags = [];
        this.category = "";
        this.communityType = OM.CommunityType.Public;
        this.isOnlineEvent = false;
    }
}

export class FileIdentifier {
    file: File;
    identifier: string;
    constructor(){
        this.file = null;
        this.identifier = "";
    }
}

export class NotificationDictionary
{
    route: string;
    itemIdentifier: string;
    parentIdentifier: string;
    grandParentIdentifier: string;
    containerIdentifier: string;
    notificationType: string;
}

export class CreateChatRoomVm extends OM.CreateChatRoomVm
{
    image: File;

    constructor(){
        super();

        this.name = "";
        this.description = "";
        this.image = null;
        this.participants = [];
    }
}

export class UoseWriterCommunities {
    identifier: string;
    name: string;
    startIndex: number;
    endIndex: number;
}

export class UpdateChatRoomVm extends CreateChatRoomVm {
    identifier: string;
    constructor(){
        super();
        this.name = "";
        this.description = "";
        this.image = null;
        this.participants = [];
        this.identifier = "";
    }
}

export class SocialSearchResponseVm
{
    conversations: OM.ChatListVm[];
    users: OM.NameIdentifier[];
    constructor(){
        this.conversations = [];
        this.users = [];
    }
}

export class SaveForumTagVM extends OM.SaveForumTagVM {

    constructor(){
        super();

        this.tag.identifier = "00000000-0000-0000-0000-000000000000";
        this.communityIdentifier = "" ;
        this.tag = new OM.ForumTag();
        this.published = false ;
    }
}

export class ChatRoomMessage
{
    identifier: string;
    date: string;
    senderIdentifier: string;
    text: string;
    quotedMessage: ChatRoomMessage;
    metaData: string;
}
export class PresentMessage
{
    chatRoomIdentifier: string;
    userIdentifier: string;
    chatRoomType: string;
}
export class DisconnectMessage
{
    chatRoomIdentifier: string;
    userIdentifier: string;
    chatRoomType: string;
}
export class ChatMessage
{
    chatRoomIdentifier: string;
    message: OM.ChatRoomMessageVM;
}
export class NamesDictionaryMessage
{
    chatRoomIdentifier: string;
    namesDictionary: { [key: string]: string };
}
export class DisableMessage
{
    chatRoomIdentifier: string;
    disable: boolean;
}
export class UpgradeMessage
{
    chatRoomIdentifier: string;
}
export class RemoveMessage
{
    chatRoomIdentifier: string;
    messageIdentifier: string;
    senderIdentifier: string;
}
export class WsClientMessage
{
    type: string;
    content: any;
}
export class WsServerMessage<T>
{
    type: string;
    content: T;
}

export var genders = {
    male : 'male',
    female : 'female',
    notSpecified : 'notSpecified',
};