import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/allPeople/:notCommunity?',
            name: 'all_people',
            meta: {
                hideFooter: true
            },
            component: () => import('./views/allPeoplePage.vue'),
        }
    ];
};