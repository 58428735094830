import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("textarea", {
    class: "genericInput squareInput",
    style: _ctx.computedStyles,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.val = $event)),
    onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resize && _ctx.resize(...args)))
  }, null, 36)), [
    [_vModelText, _ctx.val]
  ])
}