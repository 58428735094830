class Enum {
    static GetAll(){
        let ris = [];
        for(let p in this){
            ris.push(this[p]);
        }
        return ris;
    }
}

export class AppRole extends Enum
{
    static Admin: string = "Admin";
    static Manager: string = "Manager";
    static User: string = "User";
}

export class PermissionsGroup extends Enum
{
    static Admins: string = "Admins";
    static Moderators: string = "Moderators";
    static Everyone: string = "Everyone";
}

export class ChatRoomType
{
    static ChatRoom: string = "ChatRoom";
    static CommunityRoom: string = "CommunityRoom";
    static ConversationRoom: string = "ConversationRoom";
    static FriendRequest: string = "FriendRequest";
}

export class NotificationType
{
    static AllegatoNew: string = "AllegatoNew";
    static AllegatoComment: string = "AllegatoComment";
    static AllegatoCommentComment: string = "AllegatoCommentComment";
    static CommunityPostNew: string = "CommunityPostNew";
    static CommunityPostComment: string = "CommunityPostComment";
    static CommunityPostCommentComment: string = "CommunityPostCommentComment";
    static EventPostNew: string = "EventPostNew";
    static EventPostComment: string = "EventPostComment";
    static EventPostCommentComment: string = "EventPostCommentComment";
    static FieraPostNew: string = "FieraPostNew";
    static FieraPostComment: string = "FieraPostComment";
    static FieraPostCommentComment: string = "FieraPostCommentComment";
    static FriendRequest: string = "FriendRequest";
}