
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class FileInput extends Vue {

    @Prop({
        default: ""
    }) accept: string;

    onFileSelect(event){
        let file = event.target.files[0];
        this.$emit('fileChanged', file);
    }
}

