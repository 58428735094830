import { CommonServices } from './CommonServices';
import * as OM from '@/model';
import * as VM from '@/viewModel';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }

    getUserIdentifier() {
        return this.readFromLocalStorage<string>('UserIdentifier');
    }
    setUserIdentifier(UserIdentifier: string) {
        window.localStorage.setItem('UserIdentifier', JSON.stringify(UserIdentifier));
    }

    getAuthToken(){
        return this.readFromLocalStorage<string>('authtoken');
    }

    getLoginResponse() {
        return this.readFromLocalStorage<OM.LoginResponse>('LoginResponse');
    }
    setLoginResponse(value: OM.LoginResponse){
        window.localStorage.setItem('LoginResponse', JSON.stringify(value));
    }

    getLoggedUser() {
        return this.readFromLocalStorage<OM.AppUserVm>('LoggedUser');
    }
    setLoggedUser(value: OM.AppUserVm){
        window.localStorage.setItem('LoggedUser', JSON.stringify(value));
    }    

    getSocialSearch() {
        return this.readFromLocalStorage<string>('socialSearch');
    }
    setSocialSearch(value: string){
        window.localStorage.setItem('socialSearch', JSON.stringify(value));
    }
    getSocialSearchResult() {
        return this.readFromLocalStorage<VM.SocialSearchResponseVm>('socialSearchResult') || new VM.SocialSearchResponseVm();
    }
    setSocialSearchResult(value: VM.SocialSearchResponseVm){
        window.localStorage.setItem('socialSearchResult', JSON.stringify(value));
    }
    
    getChatListVmList() {
        return this.readFromLocalStorage<OM.ChatListVm[]>('ChatListVmList') || [];
    }
    setChatListVmList(value: OM.ChatListVm[]){
        window.localStorage.setItem('ChatListVmList', JSON.stringify(value));
    }
    
    getSelectedUser() {
        return this.readFromLocalStorage<OM.NameIdentifier>('SelectedUser');
    }
    setSelectedUser(value: OM.NameIdentifier){
        window.localStorage.setItem('SelectedUser', JSON.stringify(value));
    }
    
    getGlobalSearch() {
        return this.readFromLocalStorage<string>('globalSearch');
    }
    setGlobalSearch(value: string){
        window.localStorage.setItem('globalSearch', JSON.stringify(value));
    }
    getGlobalSearchResults() {
        return this.readFromLocalStorage<OM.SearchResultVm[]>('globalSearchResults') || [];
    }
    setGlobalSearchResults(value: OM.SearchResultVm[]){
        window.localStorage.setItem('globalSearchResults', JSON.stringify(value));
    }

    setDeepLink(response: string) {
        window.localStorage.setItem('deepLink', response);
    }
    retrieveDeepLink(){           
        var deepLink = this.readFromLocalStorage<string>('deepLink');
        var identifier = this.getUserIdentifier();

        // Se l'utente è loggato cancellare il deeplink nel caso di redirect nelle viste di "begin"
        if(identifier && 
            (deepLink == '/' || deepLink == '/fbToken' || deepLink == '/welcome' || deepLink == '/login' ||
                deepLink == '/confirmedCode' || deepLink == '/resetpassword' || deepLink == '/register' )){
            deepLink = "";
        }
        
        this.setDeepLink("");
        return deepLink;
    }

    getRouteLink(){
        return this.readFromLocalStorage<string>('routeLink');
    }
    clearRouteLink(){
        window.localStorage.removeItem('routeLink');
    }

    setStartUrl(response: string) {
        window.localStorage.setItem('starturl', response);
    }
    getStartUrl(){           
        return this.readFromLocalStorage<string>('starturl');
    }

    setPreferredCulture(language: string) {
        window.localStorage.setItem('preferredCulture', language);
    }
    getPreferredCulture(){           
        var ris = this.readFromLocalStorage<string>('preferredCulture');

        if(ris)
            return ris;
        
        var languages = VM.getLanguages();
        this.setPreferredCulture("\"" + languages[0] + "\"");

        return languages[0];
    }
    
    getFirstDiaryAccessDone() {
        return this.readFromLocalStorage('firstDiaryAccessDone');
    }
    setFirstDiaryAccessDone(firstDiaryAccessDone: boolean) {
        window.localStorage.setItem('firstDiaryAccessDone', firstDiaryAccessDone.toString());
    }
}

export let StorageServices = new _StorageServices();