import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/order-list',
            name: 'order list',
            meta: { hideFooter: true },
            component: () => import('./views/orderList.vue'),
        },
        {
            path: '/order-detail/:identifier',
            name: 'order detail',
            meta: { hideFooter: true },
            component: () => import('./views/orderDetail.vue'),
        },
    ];
};