
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class ModalSuccessError extends Vue {

    @Prop({
        default: false
    }) isError: boolean;

    @Prop({
        default: ""
    }) message: string;

    @Prop({
        default: "Ok"
    }) buttonText: string;

    @Prop({
        default: ""
    }) buttonUrl: string;

    created() {

    }

    goTo(buttonUrl: string) {
        if(this.buttonUrl != null && this.buttonUrl != "") {
            this.$router.push(buttonUrl);
        }
        this.$emit('close');
    }

}
