import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any) {
  const _component_file_input = _resolveComponent("file-input")!

  return (_openBlock(), _createBlock("div", {
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args))),
    class: "pointer position-relative"
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (!_ctx.isCordova)
      ? (_openBlock(), _createBlock(_component_file_input, {
          key: 0,
          ref: "fileInput",
          style: {"position":"absolute","width":"100%","height":"100%","top":"0px","left":"0","visibility":"hidden"},
          value: _ctx.file ? _ctx.file.filename : '',
          onFileChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.file = $event)),
          accept: _ctx.accept
        }, null, 8, ["value", "accept"]))
      : _createCommentVNode("", true)
  ]))
}