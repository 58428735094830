
import { Options, Vue } from 'vue-class-component';
import { NotificationClient, BaseChatClient, ApprovationRequestClient, LocalizedClient, AuthClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import { loginModule } from '@/views/login';
import { fixUrlShare } from '@/utils';
import { baseUrl, selfUrl } from '@/config';
import GlobalSpinner from '@/components/globalSpinner.vue';
import ConfirmModal from '@/components/confirmModal.vue';
import store from '@/store';
import { LoginServices } from './services/LoginServices';
import { initFirebase } from './services/Firebase/FirebaseServices';
import { CommonServices } from './services/CommonServices';
import { LocalizationServices } from './services/LocalizationServices';
import router from './router';

@Options({
    components: {
        GlobalSpinner
    }
})
export default class App extends Vue {

    loaded: boolean = false;

    created() {

        CommonServices.showSpinnerCb = () => {
            store.state.globalSpinner ++;
        }
        CommonServices.hideSpinnerCb = () => {
            store.state.globalSpinner --;
        }

        if(!store.state.preferredCulture)
            store.state.preferredCulture = StorageServices.getPreferredCulture();

        if(!store.state.user)
            store.state.user = StorageServices.getLoggedUser();

        if(!window.cordova)
            initFirebase();

        LocalizedClient.getAll()
        .then( x => {
            store.state.consts.localizedValues = x;
        }).finally( () => {
            this.loaded = true;
        })

        fixUrlShare();

        if(window.cordova){
            document.addEventListener("deviceready", () => {

                document.addEventListener("pause", this.onPause, false);
                document.addEventListener("resume", this.onResume, false);

                window.addEventListener('keyboardWillShow', () => {
                    document.body.classList.add('keyboardOpen');
                }, false);
                window.addEventListener('keyboardWillHide', () => {
                    document.body.classList.remove('keyboardOpen');
                }, false);

                document.addEventListener("backbutton", () => {
                    if(this.$opModal.modals().length > 0){
                        this.$opModal.closeLast();
                        return;
                    }

                    if(this.$route.name == "discover" || this.$route.name == "welcome"){
                        var text = LocalizationServices.getLocalizedValue("app_vuoichiuderelapp", "Vuoi chiudere l'app?");
                        this.$opModal.show(ConfirmModal, {
                            text: text, 
                            okCb: (x: boolean) => {
                                (<any>window.navigator).app.exitApp();
                                this.$opModal.closeLast();
                            }
                        })
                    } else {
                        this.$router.back();
                    }
                }, false);

            }, false);
        }
        
        store.state.checkNotificationFunction = this.checkNotty;
        store.state.checkNotificationFunction();
        this.setNottyInterval();
    }

    mounted(){
        this.registerAndTagClicked();

    }

    registerAndTagClicked(){
        var scopedView = this;
        document.onclick = function (e: any) {
            e = e ||  window.event;
            var element = e.target || e.srcElement;
            var path = e.path || e.composedPath();

            console.log(path.find(x => x.tagName == "A"))

            if (path.some(x => x.tagName == 'A' && (
                    (x.href && x.href.indexOf(selfUrl + "#/") != -1 || x.href.indexOf(selfUrl) != -1) ||
                    (x.href && x.href.indexOf("https://localhost/#/") != -1 || x.href.indexOf("https://localhost/") != -1) ||
                    (x.href && x.href.indexOf("http://localhost/#/") != -1 || x.href.indexOf("http://localhost/") != -1) || 
                    (x.href && x.href.indexOf("file:///") != -1)
                ))) {
                    
                var finalPath = path.find(x => x.tagName == 'A').href;

                if(finalPath.indexOf("index.html#") != -1)
                    return;
                
                e.preventDefault();
                scopedView.$opModal.closeAll();
                finalPath = finalPath.replace(selfUrl + "#", "");
                finalPath = finalPath.replace(selfUrl, "");
                finalPath = finalPath.replace("https://localhost/#", "");
                finalPath = finalPath.replace("https://localhost", "");
                finalPath = finalPath.replace("http://localhost/#", "");
                finalPath = finalPath.replace("http://localhost/", "");
                finalPath = finalPath.replace("file://#", "");
                finalPath = finalPath.replace("file://", "");
                
                if(finalPath && finalPath[0] != "/")
                    finalPath = "/" + finalPath;

                router.push(finalPath);
            } else if(path.some(x => x.tagName == 'A' && x.target == "_blank")){

                e.preventDefault();
                var found = path.find(x => x.tagName == 'A' && x.target == "_blank");
                store.state.openBlank(found.href)
            }
        };
    }

    onPause(){
        this.cancelNottyInterval();
    }

    onResume(){
        this.setNottyInterval();
    }
    
    nottyInterval = null;
    setNottyInterval(){
        this.nottyInterval = setInterval(() => {
            store.state.checkNotificationFunction();
        }, 10000)
    }

    cancelNottyInterval(){
        clearInterval(this.nottyInterval);
    }

    checkNotty() {
        if(!store.state.user || !store.state.user.identifier) {
            return;
        }

        AuthClient.checkLogin(false)
        .then(x => {
            Promise.all([
                BaseChatClient.hasNewMessages(store.state.user.identifier,false),
                NotificationClient.getNewNotificationCount(store.state.user.identifier,false),
                ApprovationRequestClient.checkMyInvitation(false)
            ])
            .then(xs => {
                store.state.newChatMessages = xs[0];
                if(this.$route.name == 'chat_base'){
                    var foundIndex = store.state.newChatMessages.findIndex( x => x == this.$route.params.identifier.toString());
                    store.state.newChatMessages.splice(foundIndex, 1);
                }

                store.state.newNotificationCount = xs[1];
                store.state.newInvitationCount = xs[2];
            })
        })
        .catch(y => {
            LoginServices.logout();
        })
    }

}
